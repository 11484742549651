<script setup></script>

<template>
  <svg class="svg-symbols" xmlns="http://www.w3.org/2000/svg">
    <symbol id="logo" viewBox="0 0 240 24.942">
      <!-- symbol fill -->
      <g transform="translate(0 0)">
        <path
          d="M223.823,14.868h-4.782l3.4-3.766h4.783Zm-87.284,0h-4.782l3.4-3.766h4.782Zm91.963,0L233.251,9.6H219.11l-4.749,5.265H204.8q1.643-1.823,3.288-3.646c.278-.309.649-.508.536-1.042-.116-.552-.475-.578-.944-.578H195.4l-4.748,5.265H180.362L185.109,9.6h-4.678l-4.748,5.265h-9.566l2.5-2.777c.209-.231.419-.463.628-.7a4.236,4.236,0,0,0,.612-.7.624.624,0,0,0-.106-.865c-.265-.276-.553-.224-.871-.224H161.4l1.18-1.308H157.9q-1.61,1.784-3.219,3.569a8.272,8.272,0,0,1-3.883,2.706,16.191,16.191,0,0,1-4.776.3h-4.809L145.967,9.6H122.29q1.391-1.541,2.779-3.081A17.376,17.376,0,0,1,128,3.606c2.607-1.8,5.2-1.468,8-1.468L137.933,0H131.91c-2.271,0-3.731-.035-5.848,1.085a16.541,16.541,0,0,0-4.417,4.045q-2.016,2.238-4.033,4.473h-5l-1.352,1.5h5l-3.395,3.766H101.853l3.288-3.646c.279-.309.649-.508.536-1.042-.116-.552-.475-.578-.944-.578H92.461l-4.749,5.265H77.419L82.168,9.6H77.489l-4.75,5.265H63.174l3.288-3.646c.279-.309.648-.508.537-1.042-.117-.552-.475-.578-.946-.578H53.782l-4.748,5.265H38.74L43.488,9.6H38.809l-4.748,5.265H24.5L27,12.091l.628-.7a4.256,4.256,0,0,0,.612-.7.626.626,0,0,0-.106-.865c-.265-.276-.553-.224-.872-.224H19.782l1.179-1.308H16.283l-5.929,6.574H1.616L0,16.661H8.737L1.268,24.942h4.68L18.429,11.1h4.783q-1.665,1.845-3.329,3.691a4.237,4.237,0,0,0-.756.97.729.729,0,0,0,.654.9c.827.062,1.65,0,2.474,0H52.1L57.108,11.1h4.783q-1.667,1.845-3.329,3.691a4.2,4.2,0,0,0-.756.97.728.728,0,0,0,.654.9c.827.062,1.649,0,2.473,0h29.84L95.788,11.1h4.782l-3.613,4.007c-.318.353-.7.61-.4,1.169s.748.383,1.207.383h13.487l-7.468,8.281h4.678L120.937,11.1h9.535q-1.775,1.969-3.552,3.938c-.306.34-.72.563-.506,1.143s.659.476,1.129.476h18.564c1.767,0,3.534.086,5.3-.016,4.39-.252,6.1-2.73,8.639-5.543h4.783q-1.665,1.845-3.329,3.691a4.2,4.2,0,0,0-.755.97.728.728,0,0,0,.653.9c.827.062,1.65,0,2.474,0h29.841L198.73,11.1h4.783l-3.834,4.251a.735.735,0,0,0,.114,1.219,5.717,5.717,0,0,0,1.411.088h37.178L240,14.868Z"
          transform="translate(0 0)" />
        <path
          d="M106.753,12.181h-4.679l-1.946,2.159h4.679Z"
          transform="translate(-59.106 -7.19)" />
        <path
          d="M452.425,12.181h-4.679L445.8,14.34h4.678Z"
          transform="translate(-263.155 -7.19)" />
        <path
          d="M201.16,12.181h-4.679l-1.948,2.159h4.68Z"
          transform="translate(-114.833 -7.19)" />
      </g>
    </symbol>
    <symbol id="icon-arrow" viewBox="0 0 10.499 7.858">
      <!-- symbol stroke -->
      <g transform="translate(9.828 0.333) rotate(90)" fill="none">
        <line
          y1="9.828"
          transform="translate(3.596)"
          fill="none"
          stroke-width="1" />
        <path d="M0,3.218,3.6,0l3.6,3.218" fill="none" stroke-width="1" />
      </g>
    </symbol>
    <symbol id="icon-search" viewBox="0 0 14.83 15.002">
      <!-- icon stroke  -->
      <g fill="none" stroke-width="1">
        <circle cx="6" cy="6" r="6" stroke="none" />
        <circle cx="6" cy="6" r="5.5" fill="none" />
      </g>
      <line
        x2="4.688"
        y2="4.688"
        transform="translate(9.789 9.961)"
        fill="none"
        stroke-width="1" />
    </symbol>
    <symbol id="icon-dot" viewBox="0 0 3 3">
      <!-- icon stroke  -->
      <circle cx="1.5" cy="1.5" r="1.5" stroke="none" />
    </symbol>
    <symbol id="icon-play" viewBox="0 0 6 8">
      <!-- icon fill  -->
      <g transform="translate(6) rotate(90)" stroke="none">
        <path
          d="M 7.065736293792725 5.5 L 0.9342631101608276 5.5 L 3.999999761581421 0.9013950228691101 L 7.065736293792725 5.5 Z"
          stroke="none" />
        <path
          d="M 3.999999761581421 1.802774906158447 L 1.868516445159912 5 L 6.13148307800293 5 L 3.999999761581421 1.802774906158447 M 3.999999761581421 0 L 8 6 L -4.76837158203125e-07 6 L 3.999999761581421 0 Z"
          stroke="none" />
      </g>
    </symbol>
    <symbol id="icon-chevron" viewBox="0 0 10.367 5.384">
      <!-- icon stroke  -->
      <path
        d="M0,4.341,4.849,0,9.7,4.341"
        transform="translate(10.033 4.713) rotate(180)"
        fill="none"
        stroke-width="1" />
    </symbol>
    <symbol id="icon-close" viewBox="0 0 10.258 10.258">
      <!-- symbol stroke -->
      <g transform="translate(-1.547 2.745)">
        <line
          x2="13.507"
          transform="translate(1.901 -2.391) rotate(45)"
          fill="none"
          stroke-width="1" />
        <line
          x2="13.507"
          transform="translate(11.452 -2.391) rotate(135)"
          fill="none"
          stroke-width="1" />
      </g>
    </symbol>
    <symbol id="icon-mail" viewBox="0 0 19 12">
      <g transform="translate(-130 -3277)">
        <g transform="translate(130 3277)" fill="none" stroke-width="1">
          <rect width="19" height="12" rx="1" stroke="none" />
          <rect x="0.5" y="0.5" width="18" height="11" rx="0.5" fill="none" />
        </g>
        <path
          d="M-11807.453,18025.016l9.315,6.141,8.723-6.141"
          transform="translate(11937.873 -14747.359)"
          fill="none"
          stroke-width="1" />
      </g>
    </symbol>
    <symbol id="icon-logout" viewBox="0 0 11.162 11.192">
      <g transform="translate(0.5 0.5)">
        <g transform="translate(9.991 2.231) rotate(90)">
          <line
            y1="7.287"
            transform="translate(2.865)"
            fill="none"
            stroke-width="1" />
          <path d="M0,2.565,2.865,0,5.731,2.565" fill="none" stroke-width="1" />
        </g>
        <path
          d="M11.565,16.841H6.723V6.649h4.842"
          transform="translate(-6.723 -6.649)"
          fill="none"
          stroke-width="1" />
      </g>
    </symbol>

    <symbol id="icon-document" viewBox="0 0 14.099 18.989">
      <!-- symbol fill -->
      <g transform="translate(-553.394 -1630.375)">
        <g transform="translate(553.394 1630.375)">
          <g>
            <path
              d="M1921.545,1937.788h-12.237a.932.932,0,0,1-.931-.931V1919.73a.932.932,0,0,1,.931-.931h8.4a.929.929,0,0,1,.7.318l3.839,4.373a.932.932,0,0,1,.23.613v12.754A.932.932,0,0,1,1921.545,1937.788Zm-12.007-1.161h11.776v-12.436l-3.714-4.231h-8.062Zm7.993-16.744h0Z"
              transform="translate(-1908.377 -1918.799)"
              stroke="none" />
          </g>
          <g transform="translate(8.268 0.629)">
            <path
              d="M2056,1935.157h-3.932a1.318,1.318,0,0,1-1.317-1.317v-4.216h1.161v4.216a.156.156,0,0,0,.156.155H2056Z"
              transform="translate(-2050.749 -1929.624)"
              stroke="none" />
          </g>
        </g>
      </g>
    </symbol>

    <symbol id="icon-social-linkedin" viewBox="0 0 11.633 11.124">
      <!-- symbol fill -->
      <path
        d="M83.5,5.09a2.481,2.481,0,0,0-2.247,1.236v.022h-.015c.007-.007.015-.015.015-.022V5.265H78.76c.029.705,0,7.5,0,7.5h2.494v-4.2a1.754,1.754,0,0,1,.08-.611,1.374,1.374,0,0,1,1.28-.916c.9,0,1.265.691,1.265,1.694v4.014h2.494V8.464C86.373,6.159,85.137,5.09,83.5,5.09Z"
        transform="translate(-74.739 -1.644)"
        stroke="none" />
      <path
        d="M74.641.35A1.3,1.3,0,0,0,73.23,1.644,1.287,1.287,0,0,0,74.6,2.938h.015A1.3,1.3,0,1,0,74.641.35Z"
        transform="translate(-73.23 -0.35)"
        stroke="none" />
      <rect
        width="2.494"
        height="7.504"
        transform="translate(0.145 3.621)"
        stroke="none" />
    </symbol>
    <symbol id="icon-social-facebook" viewBox="0 0 6.33 13.604">
      <!-- symbol fill -->
      <path
        d="M6.33,4.407H4.169V2.995a.576.576,0,0,1,.6-.655H6.287V.009L4.194,0a2.651,2.651,0,0,0-2.85,2.85V4.407H0v2.4H1.344v6.8H4.169v-6.8H6.074Z"
        stroke="none" />
    </symbol>
    <symbol id="icon-social-twitter" viewBox="0 0 512 512">
      <!-- symbol fill -->
      <path
        d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
        stroke="none" />
    </symbol>
    <symbol id="icon-social-instagram" viewBox="0 0 13.039 13.039">
      <!-- symbol fill -->
      <path
        d="M9.441,0H3.6A3.6,3.6,0,0,0,0,3.6V9.441a3.6,3.6,0,0,0,3.6,3.6H9.441a3.6,3.6,0,0,0,3.6-3.6V3.6A3.6,3.6,0,0,0,9.441,0Zm2.441,9.441a2.444,2.444,0,0,1-2.442,2.441H3.6A2.444,2.444,0,0,1,1.157,9.441V3.6A2.444,2.444,0,0,1,3.6,1.157H9.441A2.445,2.445,0,0,1,11.882,3.6V9.441Z"
        stroke="none" />
      <path
        d="M44.33,40.97a3.36,3.36,0,1,0,3.36,3.36A3.363,3.363,0,0,0,44.33,40.97Zm0,5.562a2.2,2.2,0,1,1,2.2-2.2A2.205,2.205,0,0,1,44.33,46.532Z"
        transform="translate(-37.81 -37.81)"
        stroke="none" />
      <path
        d="M119.768,28.25a.848.848,0,1,0,.6.248A.852.852,0,0,0,119.768,28.25Z"
        transform="translate(-109.748 -26.071)"
        stroke="none" />
    </symbol>
    <symbol id="icon-social-youtube" viewBox="0 0 13.918 10.333">
      <!-- symbol fill -->
      <g>
        <path
          d="M13.53,1.776A1.834,1.834,0,0,0,12.077.387,28.732,28.732,0,0,0,6.91,0a29.156,29.156,0,0,0-5.2.387A1.792,1.792,0,0,0,.259,1.776,21.185,21.185,0,0,0,0,5.167,17.575,17.575,0,0,0,.29,8.557,1.838,1.838,0,0,0,1.743,9.946a29.706,29.706,0,0,0,5.2.387,29.706,29.706,0,0,0,5.2-.387A1.794,1.794,0,0,0,13.6,8.557a24.775,24.775,0,0,0,.323-3.391,22.6,22.6,0,0,0-.389-3.391M5.167,7.427V2.906l3.939,2.26Z"
          stroke="none" />
      </g>
    </symbol>
  </svg>
</template>

<style scoped>
.svg-symbols {
  display: none;
}
</style>
