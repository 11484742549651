<script setup>
import {ref} from 'vue'
import footerJSON from 'assets/data/footer.json'

const processedDataMenu = inject('processedDataMenu')

const footer = ref()

const {locale} = useI18n()

const date = new Date()
const year = date.getFullYear()

const {$event} = useNuxtApp()

const openProperModal = (signal) => {
  // emit signal to open proper modal on shared bus
  $event(signal)
}

const footerContent = footerJSON[locale.value].section
</script>

<template>
  <ModalsPardotNewsletter />
  <footer class="footer" ref="footer">
    <div class="footer-wrapper">
      <div class="footer-top">
        <div class="footer-logo-wrapper">
          <svg class="icon icon--logo icon--white-fill">
            <use xlink:href="#logo"></use>
          </svg>
        </div>
        <div class="footer-social-wrapper">
          <SharedSocialMenu />
        </div>
      </div>
      <div class="footer-middle">
        <div class="footer-middle-left">
          <SharedFooterMainMenu />
        </div>
        <div class="footer-middle-center">
          <SharedFooterSecondaryMenu />
        </div>
        <div class="footer-middle-right">
          <div class="newsletter">
            <div class="newsletter-title">
              {{ $t('footer.highlights_title') }}
            </div>
            <div class="newsletter-text">
              {{ $t('footer.highlights_text') }}
            </div>
            <div
              class="cta sensible cta--light"
              @click="openProperModal('modal:newsletter')">
              {{ $t('footer.highlights_cta') }}
              <div class="menu-link-btn btn btn--round-small btn--round-light">
                <svg class="icon icon--btn-round-small">
                  <use xlink:href="#icon-arrow"></use>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="footer-bottom-left">
          <div class="footer-info footer-info--copyright">
            &copy;
            {{ footerContent.copyright + ' ' + year }} |
            {{ footerContent.vat }}
          </div>
          <div class="footer-info footer-info--company">
            {{ footerContent.company }}
          </div>
          <div class="footer-info footer-info--privacy">
            <SharedFooterPolicyMenu />
          </div>
        </div>
        <div class="footer-bottom-right">
          <SharedBasicCta
            :label="$t('footer.discover_store')"
            :url="footerContent.store.url"
            color="light" />
        </div>
      </div>
    </div>
    <div class="footer-hidden h-visually-hidden">
      <NuxtLink to="/en/investor-relations/">Investor Relations</NuxtLink>
      <NuxtLink to="/en/investor-relations/quotation-and-corporate-bodies/"
        >Investor Relations</NuxtLink
      >
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.footer {
  background-color: $color-blue;
  padding-bottom: pxrem(40);
  padding-top: pxrem(40);

  .footer-wrapper {
    align-items: center;
    @include make-col-ready;

    @include mq(md) {
      @include make-col(22);
      @include make-col-offset(1);
    }
  }

  .footer-logo-wrapper {
    align-items: center;
    display: flex;
    margin-bottom: pxrem(30);

    @include mq(sm) {
      margin-bottom: 0;
    }
  }

  .footer-top {
    margin-bottom: pxrem(30);

    @include mq(sm) {
      display: flex;
      justify-content: space-between;
    }
  }

  .footer-middle {
    @include mq(md) {
      border-top: 1px solid $color-gray-blue;
      display: flex;
      padding-top: pxrem(35);
    }
  }

  .footer-middle-left {
    display: none;

    @include mq(md) {
      @include make-col(16);
      display: block;
      padding-right: col-width(1);
    }

    @include mq(lg) {
      @include make-col(13);
    }
  }

  .footer-middle-center {
    display: none;

    @include mq(lg) {
      @include make-col(5);
      border-left: 1px solid $color-gray-blue;
      border-right: 1px solid $color-gray-blue;
      display: flex;
      justify-content: center;
      padding-left: pxrem(10) !important;
      padding-right: pxrem(10) !important;
    }
  }

  .footer-middle-right {
    @include make-col-ready;
    display: flex;
    padding-left: 0 !important;
    padding-right: 0 !important;

    @include mq(md) {
      @include make-col(6);
      border-left: 1px solid $color-gray-blue;
      justify-content: flex-end;
      padding-left: pxrem(10) !important;
      padding-right: pxrem(10) !important;
    }

    @include mq(lg) {
      border-left: none;
      @include make-col(4);
    }
  }

  .newsletter {
    max-width: pxrem(450);

    @include mq(lg) {
      max-width: 80%;
    }
  }

  .newsletter-title {
    @include paragraph($color-white);
    margin-bottom: pxrem(8);
  }

  .newsletter-text {
    @include paragraph($color-white);
    font-size: $font-small;
    letter-spacing: -0.25px;
    margin-bottom: pxrem(20);
    opacity: 0.6;
  }

  .footer-bottom {
    border-top: 1px solid $color-gray-blue;
    display: flex;
    justify-content: space-between;
    margin-top: pxrem(25);
    padding-top: pxrem(25);

    @include mq(md) {
      padding-top: pxrem(45);
    }
  }

  .footer-bottom-left {
    padding-right: pxrem(20);
  }
  .footer-bottom-right {
    display: none;

    @include mq(md) {
      display: block;
      flex-shrink: 0;
    }
  }

  .footer-info {
    @include label($color-white);
    line-height: 1.25;

    @include mq(md) {
      display: inline-block;
      line-height: 1;
    }

    &:not(:last-of-type) {
      margin-right: pxrem(30);
    }

    &.footer-info--privacy {
      border-top: 1px solid $color-gray-blue;
      margin-top: pxrem(25);
      padding-top: pxrem(25);
      width: 100%;

      @include mq(md) {
        border-top: none;
        margin-top: 0;
        padding-top: 0;
      }
    }
  }
}

.form--newsletter {
  .form-field {
    position: relative;
  }

  .form-item {
    @include field-reset;
    @include paragraph($color-white);
    border: 1px solid rgba($color-white, 0.5);
    border-radius: 5px;
    padding: pxrem(15) pxrem(36) pxrem(15) pxrem(20);
    transition: border-color $transition-default;
    width: 100%;

    &:focus {
      border-color: $color-white;
    }

    &::placeholder {
      color: $color-white;
      opacity: 0.5;
    }
  }

  .form-item-button {
    @include button-reset;
    bottom: 0;
    opacity: 0.5;
    padding: pxrem(5);
    position: absolute;
    right: pxrem(8);
    top: 0;
    transition: opacity $transition-default;
    z-index: 1;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
