import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

import {useTransitionComposable} from '../composables/TransitionComposable'
const {toggleTransitionComplete} = useTransitionComposable()

import {AnimationUtils} from '../composables/AnimationUtils'
const {animateOutFade, animateOutHorizontal} = AnimationUtils()

const pageTransition = {
  name: 'page-transiton',
  mode: 'out-in',

  onEnter: (el, done) => {
    done()
  },
  onLeave: (el, done) => {
    toggleTransitionComplete(false)
    const animateOutLeftItems = el.querySelectorAll('.animate-out-left')
    const animateOutFadeItems = el.querySelectorAll('.animate-out-fade')
    const animateOutRightItems = el.querySelectorAll('.animate-out-right')
    animateOutFade(animateOutFadeItems)
    animateOutHorizontal(animateOutLeftItems, -100)
    animateOutHorizontal(animateOutRightItems, 100)
    setTimeout(() => {
      done()
    }, 750)
  },
}

export default pageTransition
