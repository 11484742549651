<script setup>
const props = defineProps({
  color: {
    type: String,
    default: 'light',
    validator(value) {
      return ['dark', 'light'].includes(value)
    },
  },
  size: {
    type: String,
    default: 'regular',
    validator(value) {
      return ['small', 'regular'].includes(value)
    },
  },
})
</script>

<template>
  <div class="blob" :class="'blob--' + props.size">
    <div class="blob-wrapper">
      <div
        class="blob-circle"
        :class="'blob-circle--' + props.color"
        v-for="n in 5"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$blob-spin-time: 10s;
$blob-circle-stroke: pxrem(2);
$blob-circle-displacement: 5%;

@mixin border-gradient($from, $to, $weight: 0) {
  $mix-main: mix($from, $to);
  $mix-sub-from: mix($mix-main, $from);
  $mix-sub-to: mix($mix-main, $to);

  box-shadow: 0 1px 0 $weight rgba($mix-sub-to, 0.25),
    0 -1px 0 $weight rgba($mix-sub-from, 0.25),
    1px 0 0 $weight rgba($mix-sub-to, 0.25),
    -1px 0 0 $weight rgba($mix-sub-from, 0.25),
    1px -1px 0 $weight rgba($mix-main, 0.5),
    -1px 1px 0 $weight rgba($mix-main, 0.5), 1px 1px 0 $weight rgba($to, 0.75),
    -1px -1px 0 $weight rgba($from, 0.75);
}

.blob {
  animation: pulse 15s ease-in-out infinite;
  animation-direction: alternate;
  aspect-ratio: 1;
  height: 70vh;
  position: relative;

  @include mq(md) {
    height: auto;
  }

  &.blob--small {
    max-height: 50vh;

    @include mq(md) {
      max-height: 90vh;
    }
  }

  .blob-circle {
    @include border-gradient(#afc7f0, #ffffff);
    border: $blob-circle-stroke solid transparent;
    border-radius: 115% 140% 145% 110%/125% 140% 110% 125%;
    height: 100%;
    mix-blend-mode: screen;
    position: absolute;
    width: 100%;

    &.blob-circle--dark {
      @include border-gradient(#161c3e, #596087);
    }

    &:nth-child(1) {
      transform-origin: 50%-$blob-circle-displacement 50%;
      animation: spin $blob-spin-time linear infinite;
      opacity: 0.7;
    }

    &:nth-child(2) {
      transform-origin: 50% 50% + $blob-circle-displacement;
      animation: spin $blob-spin-time linear infinite;
      opacity: 0.2;
    }

    &:nth-child(3) {
      transform-origin: 50% + $blob-circle-displacement 50%;
      animation: spin $blob-spin-time linear infinite;
      opacity: 0.4;
    }

    &:nth-child(4) {
      transform-origin: 50% 50%-$blob-circle-displacement;
      animation: spin $blob-spin-time linear infinite;
      opacity: 0.8;
    }

    &:nth-child(5) {
      transform-origin: 50% + calc($blob-circle-displacement / 2) 50%;
      animation: spin $blob-spin-time linear infinite;
      opacity: 0.9;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.6);
  }
}
</style>
