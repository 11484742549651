<script setup>
import menuPolicyJSON from 'assets/data/menu-policy.json'
import {useLocalePath} from 'vue-i18n-routing'
const localePath = useLocalePath()
const {locale} = useI18n()
</script>

<template>
  <div>
    <nav class="menu-footer-policy">
      <ul class="menu-list">
        <li v-for="item in menuPolicyJSON[locale].menu_items" class="menu-item">
          <NuxtLink :to="localePath(item.path)" class="menu-link sensible">{{
            item.label
          }}</NuxtLink>
        </li>
        <li class="menu-item">
          <a href="#" class="menu-link sensible iubenda-cs-preferences-link">{{
            $t('policy_choices')
          }}</a>
        </li>
        <li>
          <a href="#" class="iubenda-cs-uspr-link"></a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
.menu-footer-policy {
  .menu-list {
    @include list-reset;
  }

  .menu-item {
    display: inline-block;

    &:not(:last-of-type) {
      &::after {
        color: $color-white;
        content: '|';
        display: inline-block;
        margin-left: pxrem(3);
        margin-right: pxrem(3);
      }
    }
  }

  .menu-link {
    @include label($color-white);
    text-decoration: none;
  }
}
</style>
