<script setup>
import menuSecondaryFooterJSON from 'assets/data/menu-secondary-footer.json'
import {useLocalePath} from 'vue-i18n-routing'
const localePath = useLocalePath()
const {locale} = useI18n()
</script>

<template>
  <nav class="menu-footer-secondary">
    <ul class="menu-list">
      <li
        v-for="item in menuSecondaryFooterJSON[locale].menu_items"
        :key="item.path"
        class="menu-item">
        <NuxtLink
          v-if="!item.target"
          :to="item.path[locale] ? item.path[locale] : localePath(item.path)"
          class="menu-link sensible"
          >{{ item.label }}</NuxtLink
        >
        <NuxtLink
          v-else
          :to="item.path"
          :target="item.target"
          class="menu-link sensible"
          >{{ item.label }}</NuxtLink
        >
      </li>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
.menu-footer-secondary {
  .menu-list {
    @include list-reset;
  }

  .menu-item {
    @include paragraph($color-white);
    flex-shrink: 0;
    margin-bottom: pxrem(10);
  }

  .menu-link {
    @include paragraph($color-white);
    opacity: 0.6;
    padding-bottom: 2px;
    position: relative;
    text-decoration: none;
    transition: opacity $transition-default;
    user-select: none;

    &:hover {
      opacity: 1;

      &::after {
        opacity: 1;
      }
    }
  }
}
</style>
