<script setup>
const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  required: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: [Array, String, Boolean],
  },
})

const emit = defineEmits()

const selectedValue = ref()

const changedValue = () => {
  emit('update:modelValue', selectedValue.value)
}
</script>

<template>
  <div class="custom-checbox sensible">
    <input type='hidden' :value="modelValue" :name="name">
    <input
      class="styled-checkbox"
      :name="name"
      type="checkbox"
      :value="modelValue"
      :required="required"
      true-value="Yes"
      false-value="No"
      v-model="selectedValue"
      @change="changedValue" />
    <label>{{ props.label }}</label>
  </div>
</template>

<style lang="scss" scoped>
.custom-checbox {
  position: relative;
}
.styled-checkbox {
  bottom: 0;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 1.25rem;
  z-index: 1;

  & + label {
    padding: 0;
    position: relative;
  }

  // Box.
  & + label:before {
    content: '';
    border: 2px solid $color-gray-dark;
    border-radius: 2px;
    display: inline-block;
    height: pxrem(15);
    margin-right: pxrem(10);
    vertical-align: text-top;
    width: pxrem(15);
  }

  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  & + label:after {
    background-color: transparent;
    border-radius: 2px;
    content: '';
    height: pxrem(9);
    left: pxrem(5);
    position: absolute;
    top: pxrem(5);
    transition: background-color;
    width: pxrem(9);
  }

  // Checkmark. Could be replaced with an image
  &:hover + label:after {
    background-color: $color-gray;
  }

  &:checked + label:after {
    background-color: $color-blue;
  }
}
</style>
