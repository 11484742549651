<script setup>
const handleError = () => clearError({redirect: '/'})
const error = useError()

console.error('=== 500 ERROR DEBUG ===')
console.error('Error message: ', error.value.message)
console.error('Error url: ', error.value.url)
console.error('Error stack: ', error.value.stack)
console.error('=== =============== ===')
</script>

<template>
  <div class="section section--500">
    <div class="section-wrapper">
      <div class="left-wrapper">
        <div class="elem">5</div>
        <div class="elem elem--blob"><SharedBlob /></div>
        <div class="elem">0</div>
      </div>
      <div class="right-wrapper">
        <div class="section-title">{{ $t('500.title') }}</div>
        <div class="section-description">{{ $t('500.description') }}</div>
        <NuxtLink
          class="btn btn--primary-light sensible"
          @click.prevent="handleError"
          >{{ $t('500.cta') }}</NuxtLink
        >
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.section--500 {
  background-color: $color-blue;

  .section-wrapper {
    @include make-col-ready;
    min-height: 100vh;

    @include mq(lg) {
      @include make-col(20);
      @include make-col-offset(2);
      align-items: center;
      display: flex;
    }
  }

  .left-wrapper {
    display: flex;
    padding-bottom: pxrem(50);
    padding-top: pxrem(160);

    @include mq(md) {
      padding-bottom: 0;
      padding-top: pxrem(80);
    }

    @include mq(lg) {
      @include make-col(12);
      padding-top: 0;
    }

    .elem {
      align-items: center;
      color: $color-white;
      display: flex;
      justify-content: center;
      flex-shrink: 0;
      font-size: pxrem(120);
      font-weight: 700;

      @include mq(xs) {
        font-size: pxrem(160);
      }

      @include mq(sm) {
        font-size: pxrem(220);
      }

      @include mq(md) {
        font-size: pxrem(280);
      }

      @include mq(lg) {
        font-size: pxrem(220);
      }

      @include mq(xl) {
        font-size: pxrem(260);
      }

      @include mq(xxl) {
        font-size: pxrem(300);
      }

      @include mq(full) {
        font-size: pxrem(400);
      }

      &.elem--blob {
        flex-grow: 1;
      }
    }

    .blob {
      height: 100%;
      width: 100%;
    }
  }
  .right-wrapper {
    text-align: center;

    @include mq(lg) {
      @include make-col(6);
      @include make-col-offset(2);
      text-align: left;
    }
  }

  .section-title {
    @include display-3($color-white);
    margin-bottom: pxrem(15);
  }

  .section-description {
    @include paragraph-big($color-white);
    margin-bottom: pxrem(25);
  }
}
</style>
