<script setup>
const props = defineProps({
  allLabel: {
    type: String,
    default: 'All',
  },
  addClass: {
    type: String,
  },
  name: {
    type: String,
    required: true,
  },
  options: {
    type: Object,
    required: true,
  },
  preselected: {
    type: String,
  },
  required: {
    type: Boolean,
    default: false,
  },
  searchEnabled: {
    type: Boolean,
    default: true,
  },
})

const nuxtApp = useNuxtApp()
const {$event} = useNuxtApp()
const emit = defineEmits()

const options = ref()
options.value = props.options

// const selectedOption = ref(options.value[0])
const selectedOption = ref({label: props.allLabel, value: ''})
const showOptions = ref(false)
const searchText = ref('')

// Function to find label based on value
const findLabelByValue = (value) => {
  const option = props.options.find((opt) => opt.value === value)
  return option ? option.label : null
}

// preselection
if (props.preselected) {
  selectedOption.value.label = findLabelByValue(props.preselected)
  selectedOption.value.value = props.preselected
}

// Filters options with internal search
const filteredOptions = computed(() => {
  return props.options.filter((option) =>
    option.label.toLowerCase().includes(searchText.value.toLowerCase())
  )
})

// open/close options
function toggleOptions() {
  showOptions.value = !showOptions.value
}

// Select current option in fake select
function selectOption(option) {
  selectedOption.value = option
  showOptions.value = false
  emit('update:modelValue', option.value)

  let element = {
    optionValue: option.value,
    className: props.addClass,
  }

  $event('change:selectValue', element )
}

const customSelectRef = ref(null)

onMounted(() => {
  // Add event listener to close options when clicking outside the component
  document.addEventListener('click', handleClickOutside)
})

onUnmounted(() => {
  // Remove event listener when the component is unmounted
  document.removeEventListener('click', handleClickOutside)
})

function handleClickOutside(event) {
  // Check if the clicked element is outside the custom select component
  if (customSelectRef.value && !customSelectRef.value.contains(event.target)) {
    showOptions.value = false
  }
}

function resetSelect() {
  if (props.preselected) {
    selectOption({label: props.preselected, value: props.preselected})
  } else{
    selectOption({label: props.allLabel, value: ''})
  } 
}

defineExpose({
  resetSelect
})
</script>

<template>
  <div class="custom-select sensible" ref="customSelectRef">
    <div class="custom-select-field custom-select-field--desktop">
      <div
        class="selected-option"
        :class="{'show-options': showOptions}"
        @click="toggleOptions">
        {{ selectedOption.label }}
        <svg class="select-icon icon icon--btn-round icon--gray-blue-stroke">
          <use xlink:href="#icon-chevron"></use>
        </svg>
      </div>
      <div v-if="showOptions" class="options">
        <div class="option-search-field">
          <input
            class="option-search-input"
            type="text"
            v-if="searchEnabled"
            v-model="searchText"
            :placeholder="$t('filter')" />
        </div>
        <div
          class="option-value"
          @click="selectOption({label: allLabel, value: ''})">
          {{ allLabel }}
        </div>
        <div
          class="option-value"
          v-for="option in filteredOptions"
          :key="option.value"
          @click="selectOption(option)">
          {{ option.label }}
        </div>
      </div>
    </div>
    <div class="custom-select-field custom-select-field--mobile">
      <select
        v-model="selectedOption.value"
        :required="required"
        :name="name"
        :class="addClass"
        :data-value="selectedOption.value"
        v-show="false">
        <option :value="''">{{ allLabel }}</option>
        <option
          v-for="option in filteredOptions"
          :key="option.value"
          :value="option.value">
          {{ option.label }}
        </option>
      </select>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom-select {
  .custom-select-field {
    position: relative;
  }

  .selected-option {
    @include paragraph;
    border: 1px solid $color-gray-blue;
    border-radius: 5px;
    padding: pxrem(12) pxrem(50) pxrem(12) pxrem(20);
    position: relative;
    transition: all $transition-default;
    user-select: none;

    &.show-options {
      border-bottom-color: $color-gray;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .select-icon {
        transform: translateY(-50%) rotateX(180deg);
      }
    }
  }

  .select-icon {
    position: absolute;
    right: pxrem(15);
    top: 50%;
    transform: translateY(-50%);
    transition: transform $transition-default;

    @include mq(md) {
      right: pxrem(20);
    }
  }

  .options {
    background-color: $color-white;
    border-bottom: 1px solid $color-gray-blue;
    border-left: 1px solid $color-gray-blue;
    border-right: 1px solid $color-gray-blue;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    max-height: pxrem(200);
    overflow-y: scroll;
    top: calc(1em + 2 * pxrem(17) - 1px);
    left: 0;

    position: absolute;
    right: 0;
    transition: all $transition-default;
    z-index: 1;
  }

  .option-search-field {
    padding: pxrem(15) pxrem(20) pxrem(5);
  }

  .option-search-input {
    @include field-reset;
    border: 1px solid $color-gray-blue;
    border-radius: 5px;
    cursor: none;
    padding: pxrem(5);
    width: 100%;
  }

  .option-value {
    padding: pxrem(10) pxrem(20);
    transition: background-color $transition-default;

    &:hover {
      background-color: $color-gray-light;
    }
  }
}
</style>
