import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

export const AnimationUtils = () => {
  /* reference animate-in-bottom class */
  const animateInBottom = (items) => {
    items.forEach((item) => {
      gsap.set(item, {autoAlpha: 1})

      let tlItem = gsap.timeline({autoRemoveChildren: true, paused: true})
      let stItem = null

      tlItem.from(item, {
        autoAlpha: 0,
        duration: 0.75,
        y: 100,
        ease: 'Power2.easeOut',
        onComplete: () => {
          stItem.kill()
          item.classList.remove('animate-in-bottom')
        },
      })

      stItem = ScrollTrigger.create({
        trigger: item,
        start: 'top 70%',
        end: 'bottom 90%',
        animation: tlItem,
        // markers: true,
        toggleActions: 'play none none none',
      })
    })
  }

  /* reference animate-in-fade class */
  const animateInFade = (items) => {
    items.forEach((item) => {
      gsap.set(item, {autoAlpha: 1})

      let tlItem = gsap.timeline({autoRemoveChildren: true, paused: true})
      let stItem = null

      tlItem.from(item, {
        autoAlpha: 0,
        duration: 0.75,
        ease: 'Power2.easeOut',
        onComplete: () => {
          stItem.kill()
          item.classList.remove('animate-in-fade')
        },
      })

      stItem = ScrollTrigger.create({
        trigger: item,
        start: 'top 70%',
        end: 'bottom 90%',
        animation: tlItem,
        // markers: true,
        toggleActions: 'play none none none',
      })
    })
  }

  /* reference animate-in-fade-early class */
  const animateInFadeEarly = (items) => {
    items.forEach((item) => {
      gsap.set(item, {autoAlpha: 1})

      let tlItem = gsap.timeline({autoRemoveChildren: true, paused: true})
      let stItem = null

      tlItem.from(item, {
        autoAlpha: 0,
        duration: 0.75,
        ease: 'Power2.easeOut',
        onComplete: () => {
          stItem.kill()
          item.classList.remove('animate-in-fade-early')
        },
      })

      stItem = ScrollTrigger.create({
        trigger: item,
        start: 'top 90%',
        animation: tlItem,
        // markers: true,
        toggleActions: 'play none none none',
      })
    })
  }

  /* reference animate-in-fade-late class */
  const animateInFadeLate = (items) => {
    items.forEach((item) => {
      gsap.set(item, {autoAlpha: 1})

      let tlItem = gsap.timeline({autoRemoveChildren: true, paused: true})
      let stItem = null

      tlItem.from(item, {
        autoAlpha: 0,
        duration: 0.75,
        ease: 'Power2.easeOut',
        onComplete: () => {
          stItem.kill()
          item.classList.remove('animate-in-fade-late')
        },
      })

      stItem = ScrollTrigger.create({
        trigger: item,
        start: 'top 25%',
        end: 'bottom 90%',
        animation: tlItem,
        // markers: true,
        toggleActions: 'play none none none',
      })
    })
  }

  /* reference animate-in-image class + animate-layer class*/
  const animateInImage = (items) => {
    items.forEach((item) => {
      gsap.set(item, {autoAlpha: 1})

      let tlItem = gsap.timeline({autoRemoveChildren: true, paused: true})
      let stItem = null

      const layerItem = item.querySelector('img')

      if (layerItem) {
        stItem = tlItem
          .from(item, {
            autoAlpha: 0,
            duration: 0.35,
            ease: 'Power2.easeOut',
          })
          .from(layerItem, {
            autoAlpha: 0,
            duration: 1,
            ease: 'Power2.easeOut',
          })
          .from(layerItem, {
            delay: -1.2,
            duration: 1.25,
            ease: 'Power2.easeOut',
            scale: 1.05,
            onComplete: () => {
              stItem.kill()
              item.classList.remove('animate-in-image')
            },
          })

        ScrollTrigger.create({
          trigger: item,
          start: 'top 60%',
          end: 'bottom 90%',
          animation: tlItem,
          // markers: true,
          toggleActions: 'play none none none',
        })
      }
    })
  }

  /* reference animate-in-stagger-bottom class + animate-stagger-item class */
  const animateInStaggerBottom = (items) => {
    items.forEach((item) => {
      gsap.set(item, {autoAlpha: 1})

      let tlItem = gsap.timeline({autoRemoveChildren: true, paused: true})
      let stItem = null

      // Select subitems within the main item
      const subitems = Array.from(
        item.querySelectorAll('.animate-stagger-item')
      )

      tlItem.from(subitems, {
        autoAlpha: 0,
        duration: 0.75,
        y: 100,
        ease: 'Power2.easeOut',
        stagger: 0.2,
        onComplete: () => {
          stItem.kill()
          item.classList.remove('.animate-in-stagger-bottom')
        },
      })

      stItem = ScrollTrigger.create({
        trigger: item,
        start: 'top 70%',
        animation: tlItem,
        // markers: true,
        toggleActions: 'play none none none',
      })
    })
  }

  /* reference animate-in-stagger-left class + animate-stagger-item class */
  const animateInStaggerLeft = (items) => {
    items.forEach((item) => {
      gsap.set(item, {autoAlpha: 1})

      let tlItem = gsap.timeline({autoRemoveChildren: true, paused: true})
      let stItem = null

      // Select subitems within the main item
      const subitems = Array.from(
        item.querySelectorAll('.animate-stagger-item')
      )

      tlItem.from(subitems, {
        autoAlpha: 0,
        duration: 0.75,
        x: -100,
        ease: 'Power2.easeOut',
        stagger: 0.2,
        onComplete: () => {
          stItem.kill()
          item.classList.remove('.animate-in-stagger-left')
        },
      })

      stItem = ScrollTrigger.create({
        trigger: item,
        start: 'top 70%',
        animation: tlItem,
        // markers: true,
        toggleActions: 'play none none none',
      })
    })
  }

  /* reference animate-out-fade class */
  const animateOutFade = (items) => {
    items.forEach((item) => {
      gsap.to(item, {
        autoAlpha: 0,
        duration: 0.75,
        ease: 'Power2.easeOut',
      })
    })
  }

  /* reference animate-out-left and animate-out-right classes */
  const animateOutHorizontal = (items, offset) => {
    items.forEach((item) => {
      gsap.to(item, {
        autoAlpha: 0,
        opacity: 0,
        duration: 0.75,
        x: offset,
        ease: 'Power2.easeOut',
      })
    })
  }

  return {
    animateInBottom,
    animateInFade,
    animateInFadeEarly,
    animateInFadeLate,
    animateInImage,
    animateInStaggerBottom,
    animateInStaggerLeft,
    animateOutFade,
    animateOutHorizontal,
  }
}

export default AnimationUtils
