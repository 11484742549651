<script setup>
import {useLocalePath} from 'vue-i18n-routing'
const localePath = useLocalePath()

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  url: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    default: 'dark',
    validator(value) {
      return ['dark', 'light'].includes(value)
    },
  },
  direction: {
    type: String,
    default: 'right',
    validator(value) {
      return ['right', 'down', 'left', 'up'].includes(value)
    },
  },
  position: {
    type: String,
    default: 'right',
    validator(value) {
      return ['right', 'left'].includes(value)
    },
  },
})
</script>

<template>
  <NuxtLink
    :to="(localePath(props.url)) ? localePath(props.url) : props.url"
    class="cta sensible"
    :class="'cta--' + props.color"
    :target="(!localePath(props.url)) ? '_blank' : ''">
    <div
      class="menu-link-btn btn btn--round-small"
      :class="'btn--round-' + props.color"
      v-if="props.position === 'left'">
      <svg
        class="icon icon--btn-round-small"
        :class="{
          'icon--rotate-90': props.direction == 'down',
          'icon--rotate-180': props.direction == 'left',
          'icon--rotate-270': props.direction == 'up',
        }">
        <use xlink:href="#icon-arrow"></use>
      </svg>
    </div>
    {{ props.label }}
    <div
      class="menu-link-btn btn btn--round-small"
      :class="'btn--round-' + props.color"
      v-if="props.position === 'right'">
      <svg
        class="icon icon--btn-round-small"
        :class="{
          'icon--rotate-90': props.direction == 'down',
          'icon--rotate-180': props.direction == 'left',
          'icon--rotate-270': props.direction == 'up',
        }">
        <use xlink:href="#icon-arrow"></use>
      </svg>
    </div>
  </NuxtLink>
</template>

<style scoped></style>
