/* ********** */
/* full lists */
/* ********** */

export const legalEntityListAll = [
  {
    value: 'POA',
    label: 'POA',
  },
  {
    value: 'PFSPA',
    label: 'PFSPA',
  },
  {
    value: 'PFD',
    label: 'PFD',
  },
  {
    value: 'PFS',
    label: 'PFS',
  },
]

export const legalEntityFullListAll = {
  POA: ['Miami Architecture Division', 'New York Corporation'],
  PFSPA: ['Pininfarina SPA Via Nazionale 30'],
  PFD: ['Pininfarina Deutschland'],
  PFS: ['Pininfarina China'],
}

export const verticalListAll = [
  {
    value: 'General information',
    label: 'General information',
    page: '',
  },
  {
    value: 'All sectors',
    label: 'All sectors',
    page: '',
  },
  {
    value: 'Automotive',
    label: 'Automotive',
    page: 'Automotive',
  },
  {
    value: 'Architecture',
    label: 'Architecture',
    page: 'Architecture',
  },
  {
    value: 'Nautical',
    label: 'Nautical',
    page: 'Nautical',
  },
  {
    value: 'Transportation',
    label: 'Mobility / Transportation',
    page: 'Mobility & Transportation',
  },
  {
    value: 'Industrial & Experience Design',
    label: 'Product & UX',
    page: 'Product Design',
  },
  {
    value: 'Wind Tunnel',
    label: 'Wind Tunnel',
    page: 'Wind Tunnel',
  },
  {
    value: 'PF Classiche',
    label: 'Collectors PF Classiche',
    page: '',
  },
  {
    value: 'Press',
    label: 'Press',
    page: '',
  },
  {
    value: 'Brand Extension MG',
    label: 'Brand Licensing',
    page: 'Brand Licensing',
  },
]

export const verticalLegalEntities = {
  'All sectors': ['PFSPA', 'PFD', 'PFS', 'POA'],
  'Automotive': ['PFSPA', 'PFD', 'PFS'],
  'Architecture': ['PFSPA', 'POA', 'PFS'],
  'Nautical': ['PFSPA', 'POA'],
  'Transportation': ['PFSPA', 'PFD', 'PFS', 'POA'],
  'Industrial & Experience Design': ['PFSPA', 'POA', 'PFS'],
  'Wind Tunnel': ['PFSPA'],
  'PF Classiche': ['PFSPA'],
  'Brand Extension MG': ['PFSPA', 'PFD', 'PFS', 'POA'],
}

/* ************** */
/* configurations */
/* ************** */

export const classicheConfig = {
  name: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  surname: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  email: {
    visibility: true,
    required: true,
    preselected: '',
  },
  phone: {
    visibility: false,
    required: false,
    precompiled: '',
  },
  company: {
    visibility: true,
    required: false,
    precompiled: '',
  },
  vertical: {
    visibility: false,
    required: true,
    list: verticalListAll.filter(
      (item) =>
        ![
          'All sectors',
          'General information',
          'Press',
          'Automotive',
          'Architecture',
          'Nautical',
          'Mobility & Transportation',
          'Product & UX',
          'Wind Tunnel',
          'Press',
          'Brand Licensing'
        ].includes(item.value)
    ),
    precompiled: 'PF Classiche',
  },
  legal: {
    visibility: false,
    required: false,
    precompiled: '',
  },
  message: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  fans: {
    visibility: false,
    required: true,
    precompiled: 'false',
  },
}

export const generalConfig = {
  name: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  surname: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  email: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  phone: {
    visibility: true,
    required: false,
    precompiled: '',
  },
  company: {
    visibility: true,
    required: false,
    precompiled: '',
  },
  vertical: {
    visibility: true,
    required: true,
    list: verticalListAll.filter(
      (item) => !['All sectors', 'Press'].includes(item.value)
    ),
    precompiled: 'General information',
  },
  legal: {
    visibility: false,
    required: false,
    precompiled: '',
  },
  message: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  fans: {
    visibility: false,
    required: true,
    precompiled: 'false',
  },
}

export const projectConfig = {
  name: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  surname: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  email: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  phone: {
    visibility: true,
    required: false,
    precompiled: '',
  },
  company: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  vertical: {
    visibility: true,
    required: true,
    list: verticalListAll.filter(
      (item) =>
        !['All sectors', 'General information', 'Press'].includes(item.value)
    ),
    precompiled: '',
  },
  legal: {
    visibility: false,
    required: false,
    precompiled: '',
  },
  message: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  fans: {
    visibility: false,
    required: true,
    precompiled: 'false',
  },
}

export const locationsConfig = {
  name: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  surname: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  email: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  phone: {
    visibility: true,
    required: false,
    precompiled: '',
  },
  company: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  vertical: {
    visibility: true,
    required: true,
    list: verticalListAll.filter(
      (item) => !['All sectors', 'Press', 'Brand Licensing'].includes(item.value)
    ),
    precompiled: '',
  },
  legal: {
    visibility: false,
    required: true,
    precompiled: '',
  },
  message: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  fans: {
    visibility: false,
    required: true,
    precompiled: 'false',
  },
}

export const mediaHubConfig = {
  name: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  surname: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  email: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  phone: {
    visibility: false,
    required: false,
    precompiled: '',
  },
  company: {
    visibility: false,
    required: false,
    precompiled: '',
  },
  vertical: {
    visibility: false,
    required: true,
    list: verticalListAll.filter(
      (item) =>
        ![
          'All sectors',
          'General information',
          'Automotive',
          'Architecture',
          'Nautical',
          'Mobility & Transportation',
          'Product & UX',
          'Wind Tunnel',
          'PF Classiche',
          'Brand Licensing'
        ].includes(item.value)
    ),
    precompiled: 'Press',
  },
  legal: {
    visibility: false,
    required: false,
    precompiled: '',
  },
  message: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  fans: {
    visibility: false,
    required: true,
    precompiled: 'false',
  },
}

export const newsletterConfig = {
  name: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  surname: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  email: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  phone: {
    visibility: false,
    required: false,
    precompiled: '',
  },
  company: {
    visibility: false,
    required: false,
    precompiled: '',
  },
  vertical: {
    visibility: true,
    required: true,
    list: verticalListAll.filter(
      (item) =>
        !['General information', 'PF Classiche', 'Press', 'Brand Licensing'].includes(item.value)
    ),
    precompiled: '',
  },
  legal: {
    visibility: false,
    required: false,
    precompiled: '',
  },
  message: {
    visibility: false,
    required: false,
    precompiled: '',
  },
  fans: {
    visibility: false,
    required: true,
    precompiled: 'true',
  },
}

export const sectorsConfig = {
  name: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  surname: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  email: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  phone: {
    visibility: true,
    required: false,
    precompiled: '',
  },
  company: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  vertical: {
    visibility: true,
    required: true,
    list: verticalListAll.filter(
      (item) => !['General information', 'Press', 'Brand Licensing'].includes(item.value)
    ),
    precompiled: '',
  },
  legal: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  message: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  fans: {
    visibility: false,
    required: true,
    precompiled: 'false',
  },
}

export const jobApplyConfig = {
  name: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  surname: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  email: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  phone: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  birthdate: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  cv_attachment: {
    visibility: true,
    required: true,
    precompiled: '',
  },
  portfolio_attachment: {
    visibility: true,
    required: false,
    precompiled: '',
  },
  photo_attachment: {
    visibility: true,
    required: false,
    precompiled: '',
  },
  pa_check: {
    visibility: true,
    required: true,
    precompiled: '',
    list: [
      {
        value: 'no',
        label: 'No',
      },
      {
        value: 'yes',
        label: 'Yes',
      },
    ],
  },
  ajm_check: {
    visibility: true,
    required: true,
    precompiled: '',
    list: [
      {
        value: 'no',
        label: 'No',
      },
      {
        value: 'yes',
        label: 'Yes',
      },
    ],
  },
  rel_check: {
    visibility: true,
    required: true,
    precompiled: '',
    list: [
      {
        value: 'no',
        label: 'No',
      },
      {
        value: 'yes',
        label: 'Yes',
      },
    ],
  },
  cover_letter: {
    visibility: true,
    required: false,
    precompiled: '',
  },
}
