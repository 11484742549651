import {ref} from 'vue'

export default function useApi() {
  const MAX_RETRIES = 3

  const postData = async (
    firstEndpoint,
    secondEndpoint,
    data,
    bugsnag = null,
    runningEnv = 'local'
  ) => {
    const retryEndpoint = async (endpoint, bugsnag = null) => {
      let retryCount = 0

      while (retryCount < MAX_RETRIES) {
        try {
          const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })

          if (response.ok) {
            bugSnagErrorNotify(
              bugsnag,
              `Email successfully sent (postData) - ${response.status}`,
              runningEnv
            )
            return await response.json()
          }
        } catch (error) {
          bugSnagErrorNotify(
            bugsnag,
            `Error in email sending: ${endpoint}: ${error}`,
            runningEnv
          )
          console.error(`Error in email sending: ${endpoint}:`, error)
        }
        retryCount++
      }

      bugSnagErrorNotify(
        bugsnag,
        `Email - Endpoint ${endpoint} failed after ${MAX_RETRIES} retries`,
        runningEnv
      )
      throw new Error(
        `Email - Endpoint ${endpoint} failed after multiple retries`
      )
    }

    try {
      return await retryEndpoint(firstEndpoint, bugsnag)
    } catch (error) {
      bugSnagErrorNotify(
        bugsnag,
        `Email - Error in first endpoint: ${error}`,
        runningEnv
      )
      console.error('Error in first endpoint:', error)
    }

    try {
      return await retryEndpoint(secondEndpoint, bugsnag)
    } catch (error) {
      bugSnagErrorNotify(
        bugsnag,
        `Email - Error in second endpoint: ${error}`,
        runningEnv
      )
      console.error('Email - Error in second endpoint:', error)
    }

    throw new Error('Email - Both endpoints failed after multiple retries')
  }

  return {
    postData,
  }
}

// bugsnag error notification function
const bugSnagErrorNotify = (bugsnag, errorText, runningEnv = 'local') => {
  if (bugsnag) {
    bugsnag.notify(new Error(errorText), (event) => {
      event.app.releaseStage = runningEnv
    })
  }
  return
}
