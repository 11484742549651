import {ref, onMounted} from 'vue'
import AnimationUtils from './AnimationUtils'

export async function useDataStatic(endpoint) {
  try {
    const {data} = await useFetch(endpoint)
    return {
      fetchedData: ref(data),
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    return {
      fetchedData: ref(null),
    }
  }
}

export function useAnimations() {
  const initialized = ref(false)

  const initializeAnimations = () => {
    const selectors = [
      '.animate-in-bottom',
      '.animate-in-fade',
      '.animate-in-fade-early',
      '.animate-in-fade-late',
      '.animate-in-image',
      '.animate-in-stagger-bottom',
      '.animate-in-stagger-left',
    ]

    const animationFunctions = [
      AnimationUtils().animateInBottom,
      AnimationUtils().animateInFade,
      AnimationUtils().animateInFadeEarly,
      AnimationUtils().animateInFadeLate,
      AnimationUtils().animateInImage,
      AnimationUtils().animateInStaggerBottom,
      AnimationUtils().animateInStaggerLeft,
    ]

    selectors.forEach((selector, index) => {
      const items = document.querySelectorAll(selector)
      // console.log('Selector: ', selector, ' - n-elements: ', items.length)
      animationFunctions[index](items)
    })

    initialized.value = true
  }

  const updateAnimations = () => {
    const selectors = [
      '.animate-in-bottom:not(.animate-once)',
      '.animate-in-fade:not(.animate-once)',
      '.animate-in-fade-early:not(.animate-once)',
      '.animate-in-fade-late:not(.animate-once)',
      '.animate-in-image:not(.animate-once)',
      '.animate-in-stagger-bottom:not(.animate-once)',
      '.animate-in-stagger-left:not(.animate-once)',
    ]

    const animationFunctions = [
      AnimationUtils().animateInBottom,
      AnimationUtils().animateInFade,
      AnimationUtils().animateInFadeEarly,
      AnimationUtils().animateInFadeLate,
      AnimationUtils().animateInImage,
      AnimationUtils().animateInStaggerBottom,
      AnimationUtils().animateInStaggerLeft,
    ]

    selectors.forEach((selector, index) => {
      const items = document.querySelectorAll(selector)
      // console.log('Selector: ', selector, ' - n-elements: ', items)
      animationFunctions[index](items)
    })

    initialized.value = true
  }

  setTimeout(() => {
    if (!initialized.value) {
      initializeAnimations()
    }

    // Watch for the custom event and re-run initializeAnimations when it occurs
    watchEffect((onInvalidate) => {
      const updateAnimationsHandler = () => {
        updateAnimations()
      }

      // Listen for the custom event "updateAnimationsEvent"
      window.addEventListener('updateAnimationsEvent', updateAnimationsHandler)

      // Cleanup the event listener when the component is unmounted
      onInvalidate(() => {
        window.removeEventListener(
          'updateAnimationsEvent',
          updateAnimationsHandler
        )
      })
    })
  }, 500)
}
