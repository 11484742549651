<script setup>
import {onMounted} from 'vue'

import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'

import {useTransitionComposable} from './composables/TransitionComposable'

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - Pininfarina` : 'Pininfarina'
  },
})

gsap.registerPlugin(ScrollTrigger)

const {toggleTransitionComplete} = useTransitionComposable()

onMounted(() => {
  toggleTransitionComplete(true)
})
</script>

<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
