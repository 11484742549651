<script setup>
import {ref} from 'vue'

const cursor = ref(null)
let root = null
let sensibleElems = []
let sensibleMoreElems = []
let containsIframe = []

const bounce = 'bounce-sensible'
const bounceMore = 'bounce-more'
const bounceDisappear = 'bounce-disappear'
const inactive = 'inactive'

// function that makes the mouse move
function moveMouse(e) {
  let x = e.clientX
  let y = e.clientY

  root.style.setProperty('--xPos', x - 15 + 'px')
  root.style.setProperty('--yPos', y - 15 + 'px')
}

function enableSensibleAnimation() {
  if (cursor && cursor.value) {
    cursor.value.classList.add(bounce)
    cursor.value.classList.remove(inactive)
  }
}

function disableSensibleAnimation() {
  if (cursor && cursor.value) {
    cursor.value.classList.add(inactive)
    cursor.value.classList.remove(bounce)
  }
}

function enableSensibleMoreAnimation() {
  if (cursor && cursor.value) {
    cursor.value.classList.add(bounceMore)
    cursor.value.classList.remove(inactive)
  }
}

function disableSensibleMoreAnimation() {
  if (cursor && cursor.value) {
    cursor.value.classList.add(inactive)
    cursor.value.classList.remove(bounceMore)
  }
}

function manageIframe(e) {
  if (e.target instanceof HTMLIFrameElement) {
    if (cursor && cursor.value) {
      cursor.value.classList.add(bounceDisappear)
    }
  } else {
    if (cursor && cursor.value) {
      cursor.value.classList.remove(bounceDisappear)
    }
  }
}

function getSensibleElements() {
  sensibleElems = Array.from(document.querySelectorAll('.sensible'))

  for (var i = 0; i < sensibleElems.length; i++) {
    sensibleElems[i].addEventListener('mouseover', enableSensibleAnimation)
    sensibleElems[i].addEventListener('mouseleave', disableSensibleAnimation)
  }
}

// reset cursor sensible elements removing eventListeners
function removeSensibleElements() {
  for (var i = 0; i < sensibleElems.length; i++) {
    sensibleElems[i].removeEventListener('mouseover', enableSensibleAnimation)
    sensibleElems[i].removeEventListener('mouseleave', disableSensibleAnimation)
  }
}

function getSensibleMoreElements() {
  sensibleMoreElems = Array.from(document.querySelectorAll('.sensible-more'))

  // check sensible elements
  for (var i = 0; i < sensibleMoreElems.length; i++) {
    sensibleMoreElems[i].addEventListener(
      'mouseover',
      enableSensibleMoreAnimation
    )
    sensibleMoreElems[i].addEventListener(
      'mouseleave',
      disableSensibleMoreAnimation
    )
  }
}

// reset cursor sensible more elements removing eventListeners
function removeSensibleMoreElements() {
  // check sensible more elements
  for (var i = 0; i < sensibleMoreElems.length; i++) {
    sensibleMoreElems[i].removeEventListener(
      'mouseover',
      enableSensibleMoreAnimation
    )
    sensibleMoreElems[i].removeEventListener(
      'mouseleave',
      disableSensibleMoreAnimation
    )
  }
}

function getIframes() {
  containsIframe = Array.from(document.querySelectorAll('.contains-iframe'))

  for (var i = 0; i < containsIframe.length; i++) {
    containsIframe[i].addEventListener('mousemove', manageIframe)
  }
}

// reset cursor iframes removing eventListeners
function removeIframes() {
  for (var i = 0; i < containsIframe.length; i++) {
    containsIframe[i].removeEventListener('mousemove', manageIframe)
  }
}

// reset cursor removing any modifier class
function resetCursor() {
  if (cursor && cursor.value) {
    cursor.value.classList.remove(bounce)
    cursor.value.classList.remove(bounceMore)
  }
}

onMounted(() => {
  root = document.documentElement

  // bind animation with mouse movement event
  document.addEventListener('mousemove', moveMouse)

  setTimeout(function () {
    getSensibleElements()
    getSensibleMoreElements()
    getIframes()
  }, 1500)
})

const route = useRoute()

watch(
  () => route.path,
  () => {
    resetCursor()
    removeSensibleElements()
    removeSensibleMoreElements()
    removeIframes()

    // set timeout to prevent component mount gap time
    setTimeout(function () {
      getSensibleElements()
      getSensibleMoreElements()
      getIframes()
    }, 1500)
  }
)
</script>

<template>
  <div ref="cursor" class="cursor mouse"></div>
</template>

<style lang="scss">
:root {
  --xPos: 0;
  --yPos: 0;
}

@media (pointer: fine) {
  html,
  body {
    cursor: none !important;
  }

  .sensible {
    cursor: none !important;
  }

  .cursor {
    background: $color-gray;
    border-radius: 50%;
    display: block;
    height: pxrem(20);
    left: pxrem(4);
    mix-blend-mode: difference;
    pointer-events: none;
    position: fixed;
    top: pxrem(8);
    transform: translate(var(--xPos), var(--yPos));
    transition: background $transition-mouse, border $transition-mouse,
      height $transition-mouse, left $transition-mouse,
      mix-blend-mode $transition-default, top $transition-mouse,
      width $transition-mouse;
    width: pxrem(20);
    z-index: 9999999;
  }

  .mouse {
    &::after {
      content: '';
      cursor: none !important;
      display: block;
      opacity: 0;
      transition: all $transition-default;
    }
  }

  .mouse.bounce-sensible {
    background: transparent;
    border: 2px solid $color-gray;
    box-shadow: none;
    height: pxrem(40);
    left: pxrem(-5);
    top: pxrem(-4);
    width: pxrem(40);
  }

  .mouse.bounce-more {
    background: $color-blue;
    border: none;
    box-shadow: none;

    height: pxrem(140);
    left: pxrem(-60);
    mix-blend-mode: normal;
    padding: pxrem(10);
    top: pxrem(-50);
    width: pxrem(140);

    &::after {
      @include label($color-white);
      content: 'Read more';
      display: block;
      left: 50%;
      opacity: 1;
      pointer-events: none;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  html[lang=zh-CN] {
    .mouse.bounce-more {
      &::after {
        content: '阅读更多';
      }
    }
  }

  .mouse.bounce-disappear {
    background: transparent;
    box-shadow: none;
    height: pxrem(0);
    left: pxrem(0);
    top: pxrem(0);
    width: pxrem(0);
  }
}

// devices without a fine pointer
@media (pointer: coarse), (pointer: none) {
  .cursor {
    display: none !important;
  }
}
</style>
